form input:not([type="checkbox"]) {
  border: none;
  outline: none;
  border-bottom: 1px solid grey;
  width: 100%;
}

form h1 {
  font-weight: 100;
  font-size: 26px;
  opacity: 0.7;
}

form h3 {
  text-transform: uppercase;
  font-size: 15px;
  font-weight: 800;
  opacity: 0.9;
  margin: 30px 0;
}

form label {
  opacity: 0.7;
}

form input {
  opacity: 0.7;
  padding: 5px 10px;
  margin-bottom: 15px;
}

form input:focus {
  border-bottom: 1px solid #3d99a5;
}
