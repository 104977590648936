/* taken from  "/node_modules/react-grid-layout/css/styles.css"; */

.react-grid-layout {
  position: relative;
  /* transition: height 200ms ease; */
}
.react-grid-item {
  /* transition: all 200ms ease; */
  /* transition-property: left, top; */

  /* background-color: rgba(0, 0, 0, 0.02); */
  /* border: 2px solid rgba(0, 0, 0, 0.1); */
}
.react-grid-item.cssTransforms {
  transition-property: transform;
}
.react-grid-item.resizing {
  z-index: 1;
  will-change: width, height;
}

.react-grid-item.react-draggable-dragging {
  transition: none;
  z-index: 3;
  will-change: transform;
}

.react-grid-item.dropping {
  visibility: hidden;
}

.react-grid-item.react-grid-placeholder {
  /* background: red; */
  background: rgb(64, 143, 78);
  opacity: 0.2;
  /* transition-duration: 100ms; */
  z-index: 2;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.react-grid-item > .react-resizable-handle {
  position: absolute;
  width: 20px;
  height: 20px;
  bottom: 0;
  right: 0;
  cursor: se-resize;
}

.react-grid-item > .react-resizable-handle::after {
  content: "";
  position: absolute;
  right: 3px;
  bottom: 3px;
  width: 5px;
  height: 5px;
  border-right: 2px solid rgba(0, 0, 0, 0.4);
  border-bottom: 2px solid rgba(0, 0, 0, 0.4);
}

.react-grid-item:not(:hover) > .react-resizable-handle {
  /* display: none; */
}

.grid-wrapper.grid-edit {
  background: url("https://fleet-assets.s3.us-west-1.amazonaws.com/images/636428687592643097_bw_edit-forfleet_INV-10_.png");
  background-size: 170px;
  background-repeat: repeat;
  background-color: rgba(0, 0, 0, 0.03);
  background-blend-mode: multiply;
}
