@keyframes pulse {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-359deg);
  }
}

.animation-pulse {
  animation: pulse 1.25s infinite linear;
}

.animation-start-invisible {
  opacity: 0;
}

.fade-in {
  opacity: 1;
  transition: opacity 0.5s ease-in;
}

.fade-out {
  opacity: 0;
  transition: opacity 0.5s ease-out;
}

.layout-wrapper {
  color: rgb(80, 80, 80);
  height: 100%;
}

.modal-backdrop {
  background-color: rgba(50, 50, 50, 0.7);
}

.modal-backdrop.show {
  opacity: 1;
}

/* Pilat Wide Book */
@font-face {
  font-family: "PilatWide";
  font-style: "normal";
  font-weight: 300;
  src: url("../fonts/pilat-wide-book.otf") format("opentype");
}

/* Pilat Wide Light */
@font-face {
  font-family: "PilatWide";
  font-style: "light";
  font-weight: 100;
  src: url("../fonts/pilat-wide-light.otf") format("opentype");
}

/* Open Sans Light */
@font-face {
  font-family: "OpenSans";
  font-style: "light";
  font-weight: 300;
  src: url("../fonts/OpenSans-Light.ttf") format("truetype");
}

/* Open Sans Regular */
@font-face {
  font-family: "OpenSans";
  font-style: "normal";
  font-weight: 400;
  src: url("../fonts/OpenSans-Regular.ttf") format("truetype");
}

/* Open Sans Bold */
@font-face {
  font-family: "OpenSans";
  font-style: "bold";
  font-weight: 700;
  src: url("../fonts/OpenSans-Bold.ttf") format("truetype");
}

/* roboto-300 - latin */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  src: url("../fonts/roboto-v27-latin-300.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("../fonts/roboto-v27-latin-300.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("../fonts/roboto-v27-latin-300.woff2") format("woff2"),
    /* Super Modern Browsers */ url("../fonts/roboto-v27-latin-300.woff")
      format("woff"),
    /* Modern Browsers */ url("../fonts/roboto-v27-latin-300.ttf")
      format("truetype"),
    /* Safari, Android, iOS */ url("../fonts/roboto-v27-latin-300.svg#Roboto")
      format("svg"); /* Legacy iOS */
}
/* roboto-regular - latin */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/roboto-v20-latin-regular.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("../fonts/roboto-v20-latin-regular.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("../fonts/roboto-v20-latin-regular.woff2") format("woff2"),
    /* Super Modern Browsers */ url("../fonts/roboto-v20-latin-regular.woff")
      format("woff"),
    /* Modern Browsers */ url("../fonts/roboto-v20-latin-regular.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("../fonts/roboto-v20-latin-regular.svg#Roboto") format("svg"); /* Legacy iOS */
}
/* rubik-regular - latin */
@font-face {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 800;
  src: url("../fonts/rubik-v9-latin-regular.eot"); /* IE9 Compat Modes */
  src: local("Rubik"), local("Rubik-Regular"),
    url("../fonts/rubik-v9-latin-regular.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("../fonts/rubik-v9-latin-regular.woff2") format("woff2"),
    /* Super Modern Browsers */ url("../fonts/rubik-v9-latin-regular.woff")
      format("woff"),
    /* Modern Browsers */ url("../fonts/rubik-v9-latin-regular.ttf")
      format("truetype"),
    /* Safari, Android, iOS */ url("../fonts/rubik-v9-latin-regular.svg#Rubik")
      format("svg"); /* Legacy iOS */
}

* {
  font-size: 100%;
  font-family: Roboto, Arial;
  font-weight: 300;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  font-weight: 300;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

button {
  background-color: transparent;
}

/* ARCGIS Styles */
.esri-component .esri-attribution .esri-widget,
.esri-attribution__powered-by,
esri-ui-inner-container esri-ui-corner-container {
  display: none;
}

.mapboxgl-ctrl-bottom-left,
.mapboxgl-ctrl-bottom-right {
  display: none;
}

.mapboxgl-popup-content {
  width: 400px;
  height: 300px;
}

progress::-moz-progress-bar {
  background: orange;
}
progress::-webkit-progress-value {
  background: orange;
}
progress {
  color: orange;
}

.mapboxgl-popup-close-button {
  display: none;
}
::-webkit-scrollbar {
  width: 0px;
  background: transparent; /* make scrollbar transparent */
}

/*  google maps */
.gmnoprint,
.gm-style-cc,
button.gm-control-active.gm-fullscreen-control {
  display: none;
}

/* todo: figure out if this is legal */
.gm-style > div > a img {
  display: none;
}

/* react toggle */
.react-toggle {
  touch-action: pan-x;

  display: inline-block;
  position: relative;
  cursor: pointer;
  background-color: transparent;
  border: 0;
  padding: 0;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
}

.react-toggle-screenreader-only {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.react-toggle--disabled {
  cursor: not-allowed;
  opacity: 0.5;
  -webkit-transition: opacity 0.25s;
  transition: opacity 0.25s;
}

.react-toggle-track {
  width: 30px;
  height: 16px;
  padding: 0;
  border-radius: 30px;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  transition: all 0.2s ease;
  /* keep this neutral grey; overwrite via JS theme */
  background-color: #4d4d4d;
  border-color: #4d4d4d;
}

.react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
  /* background-color: #000000; */
}

.react-toggle--checked .react-toggle-track {
  /* background-color: #19ab27; */
  background-color: rgba(0, 0, 0, 0.2);
  border: solid 1px grey;
}

.react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
  /* background-color: #128d15; */
}

.react-toggle-track-check {
  position: absolute;
  width: 14px;
  height: 10px;
  top: 0px;
  bottom: 0px;
  margin-top: auto;
  margin-bottom: auto;
  line-height: 0;
  left: 8px;
  opacity: 0;
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}

.react-toggle--checked .react-toggle-track-check {
  opacity: 1;
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}

.react-toggle-track-x {
  position: absolute;
  width: 10px;
  height: 10px;
  top: 0px;
  bottom: 0px;
  margin-top: auto;
  margin-bottom: auto;
  line-height: 0;
  right: 10px;
  opacity: 1;
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}

.react-toggle--checked .react-toggle-track-x {
  opacity: 0;
}

.react-toggle-thumb {
  transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 16px;
  height: 16px;
  /* border: 1px solid rgba(230, 160, 100, 1); */
  /* border: 1px solid #957b5f; */
  border: 1px solid #4d4d4d;
  border-radius: 50%;
  background-color: #fafafa;

  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;

  -webkit-transition: all 0.25s ease;
  -moz-transition: all 0.25s ease;
  transition: all 0.25s ease;
}

.react-toggle--checked .react-toggle-thumb {
  left: 14px;
  border-color: grey;
}

.react-toggle--focus .react-toggle-thumb {
  /* -webkit-box-shadow: 0px 0px 3px 2px #0099e0;
  -moz-box-shadow: 0px 0px 3px 2px #0099e0;
  box-shadow: 0px 0px 2px 3px #0099e0; */
}

.react-toggle:active:not(.react-toggle--disabled) .react-toggle-thumb {
  /* -webkit-box-shadow: 0px 0px 5px 5px #0099e0;
  -moz-box-shadow: 0px 0px 5px 5px #0099e0;
  box-shadow: 0px 0px 5px 5px #0099e0; */
}

.row {
  margin-left: 0px;
  margin-right: 0px;
}

/* Style the lines by removing the fill and applying a stroke */
.line {
  fill: none;
  /* stroke: #ffab00; */
  /* stroke-width: 3; */
}

.overlay {
  fill: none;
  pointer-events: all;
}

/* Style the dots by assigning a fill and stroke */
.dot {
  fill: #ffab00;
  stroke: #fff;
}

.focus circle {
  fill: none;
  stroke: steelblue;
}

/* modal */
.litmodal-modal1 {
  margin-left: -15%;
}
.litmodal-modal2 {
  margin-left: 29%;
}
.litmodal-modal2 > div {
  min-width: 500px;
  max-width: 650px;
}

.litmodal-modal2 .modal-body {
  padding: 0 !important;
}

.btn-primary,
.btn-primary:hover,
.btn-primary:focus {
  background-color: #f39d54;
  border-color: #f39d54;
}

.litmodal-modal1 > div {
  max-width: 40vw;
}

/* center preview modal  */
.litmodal-modal2 .tile-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}
/* want to use custom close button here */
.modal-header .close {
  display: none;
}

/* color picker extra styles */
.circle-picker {
  width: 100% !important;
}

/* modal fields */
.modal-field-wrapper {
  display: flex;
  margin: 16px 0px;
  font-size: 20px;
  font-weight: 300;
}
.modal-field-label {
  flex: 1;
}
.modal-field-label-extended {
  flex: 2;
}
.modal-field-element {
  flex: 3;
  display: flex;
}

select.modal-field-element,
.modal-field-element select,
input.modal-field-element,
.modal-field-element input {
  width: 100%;
  font-weight: 400;
  font-size: 18px;
  border-width: 0px;
  border-bottom: 1.5px solid rgba(0, 0, 0, 0.25);
  color: rgba(0, 0, 0, 0.7);
  background-color: transparent;
  outline: none;
}

.modal-field-wrapper input {
  padding-left: 6px;
}

@media (min-width: 576px) {
  .modal-dialog {
    min-width: 600px;
  }
}

button:focus {
  outline: none !important;
}

/* style map modal grid */
.map-wrapper .grid-wrapper {
  background-color: white;
}
.map-wrapper .tile-wrapper {
  box-shadow: none;
}

/* style map tile */
.map-tile > div > div {
  border-radius: 5px;
  /* filter: brightness(0.62) contract(4); */
  transition: all 0.2s;
}
.map-tile .gm-style > div {
  /* filter: brightness(0.62) contrast(4); */
}

.map-tile.settings-open > div:nth-child(3) > div {
  /* > div { */
  top: 10% !important;
  height: 90% !important;
}

/* quick fix for map modal image size */
.map-modal-wrapper .image-tile img {
  max-width: 100%;
  width: 200px;
  height: auto;
  transform: translateY(-15px);
}

.product-wrapper .react-grid-layout {
  margin-top: -8px;
}

#upwork .axis line,
.axis path {
  display: none;
}

.axis text {
  fill: #a0a0a0;
  font-size: 12px;
}

.axis_all path,
.axis_all line {
  display: none;
}

.axis_all text {
  fill: #333333;
  font-size: 12px;
}

.axis_grid path {
  display: none;
}

.axis_grid text {
  display: none;
}
.axis_grid line {
  stroke: #f0f0f0;
  pointer-events: none;
}

.title {
  text-anchor: start;
  font-size: 20px;
  font-weight: bold;
}

.mouseover_text {
  text-anchor: end;
  font-size: 14px;
}

.axis_title {
  text-anchor: middle;
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
}

.scatter_circle {
  stroke-width: 0.5px;
  fill-opacity: 0.3;
}

.zoom_rect {
  cursor: move;
  fill: #d0d0d0;
  fill-opacity: 0.2;
  pointer-events: all;
}

.mouseover_path {
  stroke: dimgrey;
  stroke-width: 0.5;
  visibility: hidden;
  pointer-events: none;
}

.mouseover_rect {
  fill: white;
  visibility: hidden;
}

.mouseover_text {
  visibility: hidden;
  fill: #333333;
  font-size: 12px;
  text-anchor: middle;
}

.scatter_label {
  text-anchor: middle;
  font-size: 10px;
  pointer-events: none;
}

.handle {
  fill: dimgrey;
  rx: 3;
  ry: 3;
}

.overlay {
  fill: #a0a0a0;
  fill-opacity: 0.2;
}

.selection {
  fill: #a0a0a0;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  /* cutting this out because it looks like errant padding */
  /* wondering if scrollbar styling is even relevant anymore */
  /* width: 5px; */
}

::-webkit-scrollbar-thumb {
  border-radius: 1px;
  background-color: rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

select,
input {
  /* width: 100%; */
  margin: 0;
  border-width: 0px;
  border-bottom: 1.5px solid rgba(0, 0, 0, 0.25);
  background-color: transparent;
  /* z-index: 10000; */
  outline: none;
}

/* hide plotly nonsense */
.modebar-container {
  display: none;
}
